const firebaseConfig = {
  projectId: "volley-student",
  appId: "1:29961579469:web:55caf06a0b5b3658107284",
  databaseURL:
    "https://volley-student-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "volley-student.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyD5qACK2kc5ma6e3IPeCcwIe8mPAl9FDD0",
  authDomain: "volley-student.firebaseapp.com",
  messagingSenderId: "29961579469",
  measurementId: "G-KQY64G2TSZ",
};

export default firebaseConfig;
